import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, updateDoc, doc, increment, arrayUnion } from '@firebase/firestore'
import { getStorage } from "firebase/storage";
import { addVisitTimeFBPortal, clearContactDataFBPortal, clearTempDataFBPortal, getContactsDataFBPortal, getCurrentUserPortal, getGeolocationDataFBPortal, getTempDataFBPortal, getUIDPortal, getUserEmailPortal, incrementCounterPortal, isLoggedInAndRegisteredPortal, isLoggedInPortal, saveTempDataFBPortal, submitContactDataFBPortal, submitGeolocationDataFBPortal } from "./firebase-portal-functions";

const USE_FIREBASE_PORTAL_NAME = 'portal-temp-00'

const firebaseConfig = {
    apiKey: "AIzaSyCn3PcZ8jNtV4kO8QNas4Z8wjSa4g56GbQ",
    authDomain: "slap-kard-portal-temp-00.firebaseapp.com",
    projectId: "slap-kard-portal-temp-00",
    storageBucket: "slap-kard-portal-temp-00.appspot.com",
    messagingSenderId: "182813221236",
    appId: "1:182813221236:web:4ca67147fc040bfb5c4c21"
};

export const firestoreAppPortalTemp00                   = initializeApp(firebaseConfig,  USE_FIREBASE_PORTAL_NAME );
export const portalAuthTemp00                           = getAuth(firestoreAppPortalTemp00);
export const portalStoragePortalTemp00                  = getStorage(firestoreAppPortalTemp00)
export const dbPortalTemp00                             = initializeFirestore(firestoreAppPortalTemp00, {
    //experimentalForceLongPolling: false,
    cacheSizeBytes: 1048576
});

const USE_FIREBASE_AUTH_PORTAL                          = portalAuthTemp00
const USE_FIREBASE_DB_PORTAL                            = dbPortalTemp00

export const isLoggedInPortal_TEMP_00                   = () => isLoggedInPortal( USE_FIREBASE_AUTH_PORTAL )
export const getCurrentUserPortal_TEMP_00               = () => getCurrentUserPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUserEmailPortal_TEMP_00                 = () => getUserEmailPortal( USE_FIREBASE_AUTH_PORTAL )
export const getUIDPortal_TEMP_00                       = () => getUIDPortal( USE_FIREBASE_AUTH_PORTAL )
export const isLoggedInAndRegisteredPortal_TEMP_00      = () => isLoggedInAndRegisteredPortal( USE_FIREBASE_AUTH_PORTAL )
export const incrementCounterPortal_TEMP_00             = async ( uid : string ) => await incrementCounterPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getGeolocationDataFBPortal_TEMP_00         = async ( uid : string ) => await getGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getContactsDataFBPortal_TEMP_00            = async ( uid : string ) => await getContactsDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const clearContactDataFBPortal_TEMP_00           = async ( uid : string ) => await clearContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const getTempDataFBPortal_TEMP_00                = async ( uid : string ) => await getTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const saveTempDataFBPortal_TEMP_00               = async ( uid : string, data : any  ) => await saveTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, data ) 
export const clearTempDataFBPortal_TEMP_00              = async ( uid : string ) => await clearTempDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const submitGeolocationDataFBPortal_TEMP_00      = async ( uid : string ) => await submitGeolocationDataFBPortal( USE_FIREBASE_DB_PORTAL, uid )
export const addVisitTimeFBPortal_TEMP_00               = async ( uid : string ) => await addVisitTimeFBPortal( USE_FIREBASE_DB_PORTAL, uid )

export const submitContactDataFBPortal_TEMP_00          = async ( uid : string, contactData : any  ) => await submitContactDataFBPortal( USE_FIREBASE_DB_PORTAL, uid, contactData )